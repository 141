function forms () {
    const form = document.querySelectorAll('.form-consult');

    if(form.length > 0) {
        const input = document.querySelectorAll('.form-input');
        const submitBtn = document.querySelectorAll('.form-submit');

        submitBtn.forEach(el=> {
            el.disabled = true;
        });

        input.forEach(el=> {
            el.addEventListener('blur', function() {
                const parent = this.closest('.form-consult');
                const submitBtn = parent.querySelector('.form-submit');

                if(!this.value.includes('@') || this.value.length === 0) {
                    this.classList.add('error');
                    submitBtn.disabled = true;
                } else {
                    this.classList.remove('error');
                    submitBtn.disabled = false;
                }
            });
        });
    }
}

export default forms;