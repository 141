function popup () {
    /**
     * Classic popup repair
     */

    const showPopupBtn = document.querySelectorAll('.popup-show');

    if(showPopupBtn.length > 0) {
        const popup = document.querySelectorAll('.popup');
        const closePopupBtn = document.querySelectorAll('.popup-close');
        const popupContent = document.querySelectorAll('.popup-content');

        showPopupBtn.forEach(el=> {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                const type = this.dataset.showpopup;
                popup.forEach(el=> el.classList.remove('show'));
                document.querySelector(`[data-popupType="${type}"]`).classList.add('show');

                const category = this.dataset.showcontent;
                popupContent.forEach(el=> el.classList.remove('show'));
                document.querySelector(`[data-popupContent="${category}"]`).classList.add('show');
            });
        });

        closePopupBtn.forEach(el=> {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                const parent = this.closest('.popup');
                parent.classList.remove('show');
                popupContent.forEach(el=> el.classList.remove('show'));
            });
        });

        document.addEventListener('click', function(e) {
            if(e.target && e.target.classList.contains('popup')) {
                e.target.classList.remove('show');
                popupContent.forEach(el=> el.classList.remove('show'));
            }
        });
    }

}

export default popup;