function switchModule () {
    /*
    Switch on tech page
    */
    const techSwitch = document.querySelectorAll('.tech__graph-switch-switch');

    if(techSwitch) {
        const switchVar = document.querySelectorAll('.switch-var');

        switchVar.forEach(el=> {
            el.addEventListener('click', function() {
                const parent = this.closest('.tech__graph-img-wrapper');
                const images = parent.querySelectorAll('.tech__graph-img');
                const vars = parent.querySelectorAll('.switch-var');
                const switchBtn = parent.querySelector('.tech__graph-switch-switch');

                vars.forEach(el=> el.classList.remove('active'));
                this.classList.add('active');

                if(this.classList.contains('switch-var--one')) {
                    images[1].style.opacity = '0';
                    switchBtn.classList.remove('switched');
                } else {
                    images[1].style.opacity = '1';
                    switchBtn.classList.add('switched');
                }
            });
        });

        techSwitch.forEach(el=> {
            el.addEventListener('click', function(e) {
                const parent = this.closest('.tech__graph-img-wrapper');
                const images = parent.querySelectorAll('.tech__graph-img');
                const titles = parent.querySelectorAll('.tech__graph-switch-title');

                this.classList.toggle('switched');

                if(this.classList.contains('switched')) {
                    images[1].style.opacity = '1';

                    titles[0].classList.remove('active');
                    titles[1].classList.add('active');
                } else {
                    images[1].style.opacity = '0';

                    titles[0].classList.add('active');
                    titles[1].classList.remove('active');
                }
            });
        });
    }
}

export default switchModule;