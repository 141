import nav from "./modules/nav";
import slider from "./modules/slider";
import anim from "./modules/anim";
import lottie from "./modules/lottie";
import tooltips from "./modules/tooltips";
import tabs from "./modules/tabs";
import samples from "./modules/samples";
import mask from "./modules/mask";
import cart from "./modules/cart";
import anchors from "./modules/anchors";
import scroll from "./modules/scroll";
import switchModule from "./modules/switch";
import hoverEffect from "./modules/hoverEffect";
import game from "./modules/game";
import popup from "./modules/popup";
import forms from "./modules/forms";

document.addEventListener('DOMContentLoaded', ()=> {
    function startJS () {
        nav();
        slider();
        anim();
        lottie();
        tooltips();
        tabs();
        samples();
        mask();
        cart();
        anchors();
        scroll();
        switchModule();
        hoverEffect();
        game();
        popup();
        forms();
    }

    const preloader = document.querySelector('.preloader');

    if(preloader) {
        document.body.style.overflow = 'hidden';

        setTimeout(()=> {
            document.body.style.overflow = 'initial';
            preloader.classList.add('hidden');
            startJS ();
        }, 5800);
    } else {
        startJS ();
    }
});