function tooltips() {
    /*
    Configure the display of the tooltip text
    */
    const tooltipBtns = document.querySelectorAll('.show-tooltip');

    if(tooltipBtns.length > 0) {
        tooltipBtns.forEach(el=> {
            el.addEventListener('mouseenter', function() {
                const parent = this.closest('.tooltip-parent');
                const targetTooltipTxt = parent.querySelector('.tooltip-content');

                targetTooltipTxt.classList.add('show');
            });

            el.addEventListener('mouseleave', function() {
                const parent = this.closest('.tooltip-parent');
                const targetTooltipTxt = parent.querySelector('.tooltip-content');

                targetTooltipTxt.classList.remove('show');
            });
        });
    }


    /*
        Product page
    */
    const productTooltips = document.querySelectorAll('.product__cart-tooltip p');

    if(productTooltips.length > 0) {
        const productTooltipWrapper = document.querySelector('.product__cart-tooltip');
        const tooltipsList = document.querySelector('.product__warranty-list');
        const tooltipTriangle = document.querySelector('.product__cart-tooltip div');
        const tooltipTrigger = document.querySelectorAll('.product__warranty-item');

        tooltipTrigger.forEach(el=>el.classList.add('active'));

        tooltipTrigger.forEach((el, i)=> {
            el.addEventListener('mouseenter', function() {
                productTooltipWrapper.classList.add('show');
                productTooltips.forEach(el=>el.classList.remove('show'));
                productTooltips[i].classList.add('show');

                if(window.innerWidth >= 1260) {
                    tooltipTrigger.forEach(el=>el.classList.remove('active'));
                    this.classList.add('active');
                    tooltipTriangle.style.top = `${((i + 1) * 78) + i * 20}px`;
                }
            });
        });

        tooltipsList.addEventListener('mouseleave', ()=> {
            productTooltipWrapper.classList.remove('show');
            productTooltips.forEach(el=>el.classList.remove('show'));

            if(window.innerWidth >= 1260) {
                tooltipTrigger.forEach(el=>el.classList.add('active'));
            }
        });
    }


    /*
    Customize the display of tooltips when scrolling
    */
    // const tooltipTrigger = document.querySelectorAll('.tooltip-trigger');

    // if(tooltipTrigger.length > 0) {
    //     const tooltipsBlock = document.querySelectorAll('.tooltips');

    //     tooltipTrigger.forEach((el, i)=> {
    //         //The distance from the beginning of the page to the block with the tooltip, which has been scrolled to
    //         const offset = el.offsetTop;
    
    //         document.addEventListener('scroll', ()=> {
    //             //If the viewport reaches the block with tooltips, we enable them
    //             if(window.scrollY >= (offset - ((window.innerHeight / 2) - 100))) {
    //                 tooltipsBlock[i].classList.remove('hidden');
    //             //If the user scrolls back, we disable them
    //             } else {
    //                 tooltipsBlock[i].classList.add('hidden');
    //             }
    //             //If the viewport aligns with the height of the block with tooltips, we disable them to show others
    //             if(window.scrollY >= offset) {
    //                 tooltipsBlock[i].classList.add('hidden');
    //             }
    //         });
    //     });
    // }
}

export default tooltips;