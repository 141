function samples () {
    /*
        Set up changing samples at hover to the target tabs on Home page
    */
    const samplesBtnsHome = document.querySelectorAll('.sample-change-home');

    if(samplesBtnsHome.length > 0) {
        const sampleImg = document.querySelector('.sample-img');

        samplesBtnsHome.forEach((el, i)=> {
            el.addEventListener('mouseenter', (e)=> {
                sampleImg.src = `../assets/images/products/samples/${i + 1}.png`;
            });

            el.addEventListener('mouseleave', (e)=> {
                sampleImg.src = `../assets/images/products/samples/1.png`;
            });
        });
    }


    /*
        Set up changing samples at hover to the target tabs on Products page
    */
    const samplesBtnsProducts = document.querySelectorAll('.sample-change-products');

    if(samplesBtnsProducts.length > 0) {
        const sampleImg = document.querySelectorAll('.sample-img');
        const orionImages = [];
        const vegaImages = [];
    
        // Preload images for ORION
        for (let i = 1; i <= samplesBtnsProducts.length; i++) {
            orionImages[i - 1] = new Image();
            orionImages[i - 1].src = `../assets/images/catalog/${i}.png`;
        }
    
        // Preload images for VEGA
        for (let i = 1; i <= samplesBtnsProducts.length; i++) {
            vegaImages[i - 1] = new Image();
            vegaImages[i - 1].src = `../assets/images/catalog/${i}.png`;
        }
    
        samplesBtnsProducts.forEach((el, i) => {
            el.addEventListener('mouseenter', function(e) {
                // Get the parent element of the current button
                const parent = e.target.closest('.samples-parent');
                // Get the product title element within the parent
                const productTitle = parent.querySelector('.products__title');
    
                // Change the image based on the product title
                if (productTitle.innerText === 'ORION')
                    sampleImg[0].src = orionImages[i].src;
    
                if (productTitle.innerText === 'VEGA')
                    sampleImg[1].src = vegaImages[i].src;

                if(this.dataset.linkproduct) {
                    const productLinkData = this.dataset.linkproduct;
                    const parent = this.closest('.samples-parent');
                    const productLink = parent.querySelector('.sample-product-catalog');

                    productLink.href = productLinkData;
                }
            });
    
            el.addEventListener('mouseleave', function(e) {
                if(!this.dataset.linkproduct) {
                    // Get the parent element of the current button
                    const parent = e.target.closest('.samples-parent');
                    // Get the product title element within the parent
                    const productTitle = parent.querySelector('.products__title');
        
                    // Reset the image based on the product title
                    if (productTitle.innerText === 'ORION')
                        sampleImg[0].src = '../assets/images/catalog/1.png';
        
                    if (productTitle.innerText === 'VEGA')
                        sampleImg[1].src = '../assets/images/catalog/5.png';
                }
            });
        });
    }
}

export default samples;