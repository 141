function anim() {
    /*===========
    Standard animation of elements when scrolling
    ===========*/
    const anim = document.querySelectorAll('[data-anim]');
    const animSlider = document.querySelectorAll('[data-animSlider]');
    
    function animToScroll(el) {
        const observerAnim = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    let time = entry.target.getAttribute('data-time');

                    if(!time) {
                        entry.target.classList.add('anim');
                    } else {
                        setTimeout(()=> {
                            entry.target.classList.add('anim');
                        }, time);
                    }
                }
            });
        }, {threshold: [.4]});
        observerAnim.observe(el);
    }

    if(anim.length > 0) anim.forEach(el=> {animToScroll(el)});
    if(animSlider.length > 0 && window.innerWidth <= 1260) animSlider.forEach(el=> {animToScroll(el)});

    
    /*===========
    Animations that trigger immediately when the page loads
    ===========*/
    const animMomentumEl = document.querySelectorAll('[data-animMomentum]');

    if(animMomentumEl.length > 0) {
        animMomentumEl.forEach(el=> {
            let time = el.getAttribute('data-time');

            if(!time) {
                el.classList.add('anim');
            } else {
                setTimeout(()=> {
                    el.classList.add('anim');
                }, time);
            }
        });
    }

    
    /*===========
    Animation for footnote
    ===========*/
    const animFootnote = document.querySelectorAll('[data-anim="footnote"]');

    function animFootnoteFunc(el) {
        const observerAnim = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    let time = entry.target.getAttribute('data-time');

                    if(!entry.target.getAttribute('data-time')) {
                        entry.target.classList.add('active');
                    } else {
                        setTimeout(()=> {
                            entry.target.classList.add('active');
                        }, time);
                    }
                } else {
                    let inversion = entry.target.getAttribute('data-inversion');

                    if(inversion) {
                        entry.target.classList.remove('active');
                    }
                }
            });
        }, {threshold: [.9]});
        observerAnim.observe(el);
    }

    if(animFootnote.length > 0) animFootnote.forEach(el=> {animFootnoteFunc(el)});
}

export default anim;