function mask () {
    const mask = document.querySelector('.mask');

    if (mask && window.innerWidth >= 1261) {
        const circle = document.querySelector('.mask__circle');
        const image = document.querySelector('.mask__img');

        document.addEventListener('mousemove', (e) => {
            // Check if mousemove event occurs on the mask element
            if (e.target && e.target.classList.contains('mask')) {
                // Get the bounding rectangle of the mask element
                const rect = mask.getBoundingClientRect();
                // Calculate the x and y coordinates relative to the mask element
                const x = e.clientX - mask.offsetLeft;
                const y = e.clientY - rect.top;

                // Make the image visible
                image.style.display = 'block';

                // Position the circle element based on mouse coordinates
                circle.style.left = (x - 100) + 'px'; // Adjusted left position
                circle.style.top = (y - 100) + 'px'; // Adjusted top position

                // Update the clipped image with a circle centered at the mouse position
                updateImage(x, y);
            }
        });

        // Function to update the clipped image based on mouse position
        function updateImage(x, y) {
            // Get the bounding rectangle of the image element
            const rect = image.getBoundingClientRect();
            // Calculate the offsetX and offsetY relative to the image element
            const offsetX = x - rect.left;
            const offsetY = y - rect.top;

            // Apply the clipped circle to the image
            image.style.clipPath = `circle(200px at ${offsetX}px ${offsetY}px)`; // Circle radius 200px
            circle.style.display = 'block'; // Make the circle visible
        }
    }
}

export default mask;