function nav () {
    const header = document.querySelector('.header');
    const subnavTrigger = document.querySelectorAll('.subnav-trigger');
    const subNav = document.querySelectorAll('.header__subnav');
    const subNavBacking = document.querySelector('.header__backing');

    /*
        Nav setting for desktop
    */
    subnavTrigger.forEach((el, i)=> {
        el.addEventListener('mouseenter', function() {
            const parent = this.closest('.header__nav-item');
            const targetSubNav = parent.querySelector('.header__subnav');

            this.classList.add('active');
            subNavBacking.classList.add('show');
            targetSubNav.classList.add('show');

            if(!header.classList.contains('painted')) header.classList.add('painted-hvr');
        });

        el.addEventListener('mouseleave', function() {
            const parent = this.closest('.header__nav-item');
            const targetSubNav = parent.querySelector('.header__subnav');

            this.classList.remove('active');
            targetSubNav.classList.remove('show');
            subNavBacking.classList.remove('show');

            if(header.classList.contains('painted-hvr')) header.classList.remove('painted-hvr');
        });
    });


    subNav.forEach(el=> {
        el.addEventListener('mouseenter', function() {
            const parent = this.closest('.header__nav-item');
            const targetParentNav = parent.querySelector('.subnav-trigger');

            this.classList.add('show');
            subNavBacking.classList.add('show');
            targetParentNav.classList.add('active');

            if(!header.classList.contains('painted')) header.classList.add('painted-hvr');
        });

        el.addEventListener('mouseleave', function() {
            const parent = this.closest('.header__nav-item');
            const targetParentNav = parent.querySelector('.subnav-trigger');

            this.classList.remove('show');
            targetParentNav.classList.remove('active');
            subNavBacking.classList.remove('show');

            if(header.classList.contains('painted-hvr')) header.classList.remove('painted-hvr');
        });
    });


    /*
        Nav setting for mob
    */
    const burger = document.querySelector('.header__burger');
    const navBlock = document.querySelector('.header__nav-inner');
    const nav = document.querySelector('.header__nav');

    burger.addEventListener('click', ()=> {
        burger.classList.toggle('active');
        nav.classList.toggle('show');

        const navBlockH = navBlock.offsetHeight;

        nav.classList.contains('show') ? nav.style.height = navBlockH + 'px' : nav.style.height = 0;
    });
}

export default nav;