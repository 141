function hoverEffect () {
    /*
        Set up hover effect on Products page
    */
    const productTrigger = document.querySelectorAll('.product-change-area');

    if (productTrigger.length > 0) {
        const productTriggerMob = document.querySelectorAll('[data-productChange="mob"]');
        const products = document.querySelector('.products');
        const triggerTitles = document.querySelectorAll('.products__change-label');
        const imagesWrapper = document.querySelectorAll('.products__img-wrapper');
        const descr = document.querySelectorAll('.products__descr-inner');
        const footnote = document.querySelectorAll('.products__footnote');
    
        // Add 'start' class to products container on initial load
        products.classList.add('start');

        function changeImg(trigger) {
            const triggerCat = trigger.dataset.trigger;

            // Remove 'start' class when mouse enters a trigger
            products.classList.remove('start');

            // Change product images based on the current trigger
            const targetCategory = trigger.dataset.trigger;

            imagesWrapper.forEach(el => { el.classList.remove('wide') });
            document.querySelector(`[data-productImages="${targetCategory}"]`).classList.add('wide');

            // Remove 'wide' class from all triggers
            productTrigger.forEach(el => el.classList.remove('wide'));
            document.querySelector(`[data-changeBtn="${targetCategory}"]`).classList.add('wide');

            // Show all trigger titles
            triggerTitles.forEach(el => el.classList.remove('hide'));

            // Hide the title of the current trigger
            if(window.innerWidth >= 1261) {
                const targetTitle = document.querySelector(`[data-triggerLabel="${triggerCat}"]`);
                targetTitle.classList.add('hide');
            }

            // Change product description based on the current trigger
            descr.forEach(el => { el.classList.add('hide') });
            document.querySelector(`[data-productDescr="${targetCategory}"]`).classList.remove('hide');

            // Animate product footnote based on the current trigger
            footnote.forEach(el => { el.classList.remove('active') });
            setTimeout(() => {
                document.querySelector(`[data-productFootnote="${targetCategory}"]`).classList.add('active');
            }, 30);
        }

        //Desktop settings
        if(window.innerWidth >= 1261) {
            document.body.addEventListener('mousemove', function(e) {
                const centerHorizontal = window.innerWidth / 2;
                const centerVertical = window.innerHeight / 2;

                if(e.clientX > centerHorizontal + 600 || e.clientX < centerHorizontal - 600) {
                    products.classList.add('start');
                }

                if(e.clientY > centerVertical + 270 || e.clientY < centerVertical - 270) {
                    products.classList.add('start');
                }
            });

            productTrigger.forEach(el => {
                el.addEventListener('mouseenter', function() {
                    changeImg(this);
                });
            });
        }


        //Tablets and mobs settings
        if(window.innerWidth <= 1260) {
            productTriggerMob.forEach(el=> {
                el.addEventListener('click', function() {
                    changeImg(this);
                });
            });

            productTrigger.forEach(el => {
                el.addEventListener('click', function() {
                    if(!el.classList.contains('wide')) {
                        changeImg(this);
                    }
                });
            });
    
            const triggerBack = document.querySelector('.products__start-back');
    
            triggerBack.addEventListener('click', ()=> {
                descr.forEach(el => { el.classList.remove('hide') });
                footnote.forEach(el => { el.classList.remove('active') });
                products.classList.add('start');
                productTrigger.forEach(el=> el.classList.remove('wide'));
            });
        }
    }
}

export default hoverEffect;