function cart () {
    /*
        Product page
    */
    //Quantity buttons on product page
    const quantityBtn = document.querySelectorAll('.quantity-btn');

    if(quantityBtn.length > 0) {
        const quantityInput = document.querySelector('.quantity-input');
        let i = 1;

        quantityInput.value = i;

        quantityBtn.forEach(el=> {
            el.addEventListener('click', function() {
                if(this.classList.contains('minus') & i >= 2)
                    i--;

                if(this.classList.contains('plus'))
                    i++;

                quantityInput.value = i;
            });
        });
    }


    //Change colors btns 
    const cartColorBtns = document.querySelectorAll('.product__cart-colors-btn');

    if(cartColorBtns.length > 0) cartColorBtns[0].checked = true;
}

export default cart;