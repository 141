function scroll () {
    /*
    About page slider anim
    */
    const sliderAbout = document.querySelector('.slider-about');

    if(sliderAbout) {
        const curtain = document.querySelector('.about__curtain');
        const sliderAboutH = sliderAbout.offsetHeight;

        document.addEventListener('scroll', (e)=> {
            if(window.innerWidth >= 1261) {
                sliderAbout.style.height = `${sliderAboutH - (window.scrollY * 3)}px`;
                if(window.scrollY >= (window.innerHeight / 2)) sliderAbout.style.height = 0;
            }

            curtain.style.opacity = 1 - ((window.scrollY - window.innerHeight / 2) / 200);
        });
    }


    /*
    Tech page hero curtain anim
    */
    const techHero = document.querySelector('.tech__hero');

    if(techHero && window.innerWidth >= 1261) {
        const curtain = document.querySelector('.tech__curtain');
        const techHeroH = techHero.offsetHeight;

        document.addEventListener('scroll', (e)=> {
            curtain.style.height = `${window.scrollY * 2}px`;
        });
    }


    /*
    Tech page benefits anim
    */
    const techBenefits = document.querySelector('.tech__benefits');

    if(techBenefits) {
        const imgWrapper = document.querySelector('.tech__benefits-img');
        const img = imgWrapper.querySelector('img');
        const items = document.querySelectorAll('.tech__benefits-item');

        items.forEach((el, i)=> {
            document.addEventListener('scroll', function() {
                const itemOffset = el.offsetTop;
                const itemH = el.offsetHeight;

                // if(window.scrollY >= itemOffset - (window.innerHeight / 2) + (itemH / 2)) {
                //     img.src = `../assets/images/tech/tech-${i}.svg`
                // }
                if(window.scrollY >= itemOffset - (window.innerHeight / 2)) 
                    img.src = `../assets/images/tech/tech-${i}.svg`;
            });
        });


        document.addEventListener('scroll', ()=> {
            window.scrollY >= techBenefits.offsetTop - (window.innerHeight / 2) 
                ? imgWrapper.classList.add('show') 
                : imgWrapper.classList.remove('show');

            if(window.scrollY >= techBenefits.offsetTop + (techBenefits.offsetHeight - window.innerHeight))
                imgWrapper.classList.remove('show');
        });
    }
}

export default scroll;