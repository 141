function slider () {
    /*
    Home page slider
    */
    const sliderHome = document.querySelector('.slider-home');

    if(sliderHome && window.innerWidth >= 1261) {
        const slides = document.querySelectorAll('.slider-home__slide');
        const animVid = document.querySelector('.home__anim');
        const backImg = document.querySelector('.home__background');
        const blinksImg = document.querySelector('.home__blinks');

        let slider = new Swiper(sliderHome, {
            direction: "vertical",
            loop: false,
            speed: 10,
            mousewheel: {
                invert: false,
                sensitivity: 5,
            },
            allowTouchMove: false,
            on: {
                init: function () {
                    document.querySelector('.header').dataset.time = 3600;

                    animVid.play();

                    animVid.addEventListener('ended', ()=> {
                        setTimeout(()=> {
                            //When the video animation is over, we hide it
                            animVid.style.opacity = '0';
                        }, 100);
                    });

                    const animElements = slides[0].querySelectorAll('[data-animSlider]');

                    setTimeout(()=> {
                        animElements.forEach(el=> {
                            const time =  el.getAttribute('data-time');
    
                            setTimeout(()=> {
                                el.classList.add('anim');
                            }, time);
                        });
                    }, 3000);
                },
            }
        });

        
        slider.on('slideChangeTransitionStart', ()=> {
            //Disable the slider temporarily to avoid multiple swipes in a row
            slider.disable();

            //Activate the slider again so you can swipe through
            animVid.addEventListener('ended', ()=> {
                setTimeout(()=> {
                    slider.enable();
                }, 2000);
            });

            //Show video animation when changing slides
            animVid.style.opacity = '1';
        });

        //Run the video from the active slide to the next slide
        slider.on('slideNextTransitionStart', function() {
            //Get the index of the previous slide and the active slide
            const activeSlideIndex = slider.activeIndex + 1;
            const prevSlideIndex = slider.activeIndex;

            //Turn on the desired video animation that corresponds to the slide
            animVid.src = `../assets/video/home/${prevSlideIndex}-${activeSlideIndex}.mp4`;
            animVid.play();
        });

        //Run the video from the active slide to the previous slide
        slider.on('slidePrevTransitionStart', function() {
            //Get the index of the next slide and the active slide
            const activeSlideIndex = slider.activeIndex + 1;
            const nextSlideIndex = activeSlideIndex + 1;

            //Turn on the desired video animation that corresponds to the slide
            animVid.src = `../assets/video/home/${nextSlideIndex}-${activeSlideIndex}.mp4`;
            animVid.play();
        });


        slider.on('slideChangeTransitionEnd', function() {
            const activeSlideIndex = slider.activeIndex + 1;

            //Show a background image that is equal to the slide sequence number
            if(activeSlideIndex != 1) {
                setTimeout(()=> {
                    backImg.src = `../assets/images/home-backgrounds/${activeSlideIndex}.jpg`;
                }, 1000);
            } else {
                setTimeout(()=> {
                    backImg.src = `../assets/images/home-backgrounds/${activeSlideIndex}.png`;
                }, 1000);
            }
            

            blinksImg.style.opacity = '1';
            blinksImg.style.display = 'none';

            //Remove the animation class from previous elements to re-run them
            const prevSlide = sliderHome.querySelector('.swiper-slide-prev');

            if(prevSlide) {
                const prevAnimElements = prevSlide.querySelectorAll('[data-animSlider]');
                prevAnimElements.forEach(el=> el.classList.remove('anim'));
            }
            
            //Remove the animation class from next elements to re-run them
            const nextSlide = sliderHome.querySelector('.swiper-slide-next');

            if(nextSlide) {
                const nextAnimElements = nextSlide.querySelectorAll('[data-animSlider]');
                nextAnimElements.forEach(el=> el.classList.remove('anim'));
            }

            //Remove animation from footnotes in samples to animate them
            const footNote = document.querySelectorAll('.samples__footnote');
            footNote.forEach(el=> el.classList.remove('active'));


            //After the video animation is over we do:
            animVid.addEventListener('ended', ()=> {
                //When the video animation is over, we hide it
                animVid.style.opacity = '0';

                //Get the active slide and find elements for animation in it
                const activeSlide = sliderHome.querySelector('.swiper-slide-active');
                const targetAnimElements = activeSlide.querySelectorAll('[data-animSlider]');

                targetAnimElements.forEach(el=> {
                    const time =  el.getAttribute('data-time');
                    
                    setTimeout(()=> {
                        el.classList.add('anim');
                    }, time);
                });

                //Animating a footnote in a samples block
                if(footNote.length > 0) {
                    setTimeout(()=> {
                        footNote.forEach(el=> el.classList.add('active'));
                    }, 500);
                }

                if(activeSlideIndex != 1) {
                    blinksImg.src = `../assets/images/home-backgrounds/blinks/${activeSlideIndex}.png`;
                    blinksImg.style.display = 'block';
                    setTimeout(()=>{
                        blinksImg.style.opacity = '0';
                    }, 100);
                } else {
                    blinksImg.src = '';
                    blinksImg.style.display = 'none';
                }
            });
        });
    }


    /*
    Product page product slider
    */
    const productSlider = document.querySelector('.slider-product');

    if(productSlider) {
        const slideChangeBtn = document.querySelectorAll('.product__view-btn');

        let slider = new Swiper(productSlider, {
            loop: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
        });

        slideChangeBtn[0].classList.add('active');

        slideChangeBtn.forEach((el, i)=> {
            el.addEventListener('mouseenter', function(e) {
                slideChangeBtn.forEach(el=>el.classList.remove('active'));
                this.classList.add('active');
                slider.slideTo(i);
            });
        });


        slider.on('slideChange', function () {
            slideChangeBtn.forEach(el=>el.classList.remove('active'));
            slideChangeBtn[slider.activeIndex].classList.add('active');
        });
    }


    /*
    Product page slider reviews
    */
    const sliderReviews = document.querySelector('.slider-reviews');

    if(sliderReviews) {
        let slider = new Swiper(sliderReviews, {
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 600,
            navigation: {
                nextEl: ".slider-reviews__btn--next",
                prevEl: ".slider-reviews__btn--prev",
            },
            pagination: {
                clickable: true,
                el: ".slider-reviews__pagination",
            },
        });
    }


    /*
    About page hero slider
    */
    const sliderAbout = document.querySelector('.slider-about');

    if(sliderAbout) {
        let slider = new Swiper(sliderAbout, {
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 3000,
            autoplay: {
                delay: 2000,
            },
        });
    }
}

export default slider;