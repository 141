function game() {
    const game = document.querySelector('.game');

    if(game) {
        /**
         * Общие настройки игры
         * Основные переменные, которые могут использоваться в разных участках скрипта задаются в начале страницы
         * Остальные переменные создаются по необходимости
        */

        //Находим окно с оповещениями и его составляющие
        const noticeWrapper = document.querySelector('.game__notice');
        const noticeInner = document.querySelectorAll('.game__notice-inner');
        const gameStartBtn = document.querySelector('.start-game');
        //Находим шейпы и остальные изображения
        const shapeWrappers = document.querySelectorAll('.game__img--shape');
        const shapes = document.querySelectorAll('.game__img--shape path');
        const persImg = document.querySelector('.game__img--pers');
        const hvrImage = document.querySelector('.game__img--hvr');
        //Находим занавеску
        const curtain = document.querySelector('.game__curtain');
        //Находим на странице вывод количества убитых
        const killed = document.querySelector('.game__killed-num--changing');
        //Находим оповещение Target detected
        const detectedNotice = document.querySelector('.game__detected');
        //Находим вывод результата в сек
        const timeResult = document.querySelector('.game__notice-timer-num--result span');

        //Создаем таймер, для вывода результата в конце игры
        let timer = 0;

        //При клике на кнопку gameStartBtn
        gameStartBtn.addEventListener('click', ()=> {
            //Прячем окно с оповещением
            noticeWrapper.classList.add('hidden');
            //Удаляем класс game-beginning
            game.classList.remove('game-beginning');

            //Запускаем таймер
            setInterval(()=> {
                timer++;
            }, 1000);
        });;


        //Создаем значение текущего уровня
        let lvl = 1;

        //Включаем первый шейп из дерева по дефолту
        shapes[0].closest('.game__img--shape').classList.add('show');

        //Перебираем все шейпы
        shapes.forEach(el=> {
            //При наведении на шейп
            el.addEventListener('mouseenter', function() {
                //Получаем значение у шейпа дата-атрибута для поиска изображений по соответствию
                const parent = this.closest('.game__img--shape');
                const targetNum = parent.dataset.shape;

                //Меняем изображение hvr соответствующее шейпу и делаем его видимым
                hvrImage.src = `../assets/images/game/hvr/${targetNum}.png`;
                hvrImage.classList.add('show');

                //Показываем оповещение Target detected на десктопах
                if(window.innerWidth >= 1261)
                    detectedNotice.classList.add('show');
            });

            //Мышь покидает шейп
            el.addEventListener('mouseleave', function() {
                //Прячем изображение hvr
                hvrImage.classList.remove('show');

                //Прячем оповещение Target detected на десктопах
                if(window.innerWidth >= 1261)
                    detectedNotice.classList.remove('show');
            });


            //При клике на шейп
            el.addEventListener('click', function() {
                //Меняем уровень игры
                lvl++;

                if(lvl <= 8) {
                    //Обновляем количество убитых врагов
                    killed.innerText = lvl - 1;
                    
                    //Задаем рандомное число от 1 до 2
                    let random = 1;
                    random = Math.floor(Math.random() * (3 - 1) + 1);

                    //Меняем изображение с персонажем
                    persImg.src = `../assets/images/game/${lvl}-${random}.png`;

                    //Меняем изображение hvr
                    hvrImage.src = `../assets/images/game/hvr/${lvl}-${random}.png`;

                    //Убираем все лишние шейпы и показываем только тот, что соответствует новому персонажу
                    shapeWrappers.forEach(el=> {
                        el.classList.remove('show');

                        if(el.dataset.shape === `${lvl}-${random}`) {
                            el.classList.add('show');
                        }
                    });

                    //Двигаем штору
                    curtain.classList.add('to-top');
                    setTimeout(()=>{
                        curtain.classList.remove('to-top')
                    }, 600);
                }

                //Если все уровни пройдены
                if(lvl === 9) {
                    //Выводим оповещение вы прошли игру
                    noticeWrapper.classList.remove('hidden');

                    const noticeFinish = document.querySelector('[data-notice="finish"]');

                    noticeInner.forEach(el=> el.classList.remove('show'));
                    noticeFinish.classList.add('show');

                    //Обновляем количество убитых врагов
                    killed.innerText = 8;

                    //Добавляем game-finish 
                    game.classList.add('game-finish');

                    //Задаем рандомное число от 1 до 9
                    let random = 1;
                    random = Math.floor(Math.random() * (10 - 1) + 1);

                    //Выводим результат скорости прохождения игры
                    //Добавляем рандомное число рядом, делая десятичным
                    timeResult.innerText = `${timer}.${random}`;
                }
            });
        });


        //Находим контейнер для пуль
        const bulletsWrapper = document.querySelector('.game__bullets');

        //Создаем и добавляем в контейнер для пуль 20 пуль
        for (let i = 0; i < 20; i++) {
            const bulletItem = document.createElement('div');
            
            bulletItem.classList.add('game__bullets-item');
            bulletsWrapper.appendChild(bulletItem);
        }
        
        //Тратим пули при каждом клике на странице
        const bullet = document.querySelectorAll('.game__bullets-item');
        //Создаем переменную для количества выстрелов
        let i = 0;

        document.querySelector('.game__inner').addEventListener('click', (e)=> {
            if(!game.classList.contains('game-beginning')) {
                //Считаем выстрелы при каждом клике
                i++;

                //Если количество выстрелов меньше или равно количеству пуль
                if (i <= 20) {
                    //Опустошаем обойму
                    bullet[20 - i].classList.add('empty');
                }

                //Когда пули закончились
                if (i >= 20) {
                    //Выводим оповещение game over
                    noticeWrapper.classList.remove('hidden');

                    const noticeGameOver = document.querySelector('[data-notice="game-over"]');

                    noticeInner.forEach(el=> el.classList.remove('show'));
                    noticeGameOver.classList.add('show');

                    //Добавляем game-beginning 
                    game.classList.add('game-beginning');
                }
            }
        });


        //Настраиваем рестарт игры
        const restartBtn = document.querySelectorAll('.restart-game');

        restartBtn.forEach(el=> {
            el.addEventListener('click', ()=> {
                //Скрываем окно с оповещением
                noticeWrapper.classList.add('hidden');
                noticeInner.forEach(el=> el.classList.remove('show'));

                //Удаляем класс game-beginning
                game.classList.remove('game-beginning');
                //Если есть game-finish, то удаляем и его
                if(game.classList.contains('game-finish'))
                    game.classList.remove('game-finish');

                //Перезаряжаем обойму
                i = 0;
                bullet.forEach(el=> el.classList.remove('empty'));

                //Сбрасываем счетчик убитых врагов
                killed.innerText = 0;

                //Сбрасываем счетчик уровней
                lvl = 1;

                //Меняем изображение с персонажем
                persImg.src = `../assets/images/game/1-1.png`;

                //Меняем изображение hvr
                hvrImage.src = `../assets/images/game/hvr/1-1.png`;

                //Убираем все лишние шейпы и показываем только тот, что соответствует новому персонажу
                shapeWrappers.forEach(el=> {
                    el.classList.remove('show');

                    if(el.dataset.shape === `1-1`) {
                        el.classList.add('show');
                    }
                });

                //Перезапускаем таймер
                timer = 0;
            });
        });



            //Отключаем кнопки в окне оповещений
            // if(window.orientation != 90) {
            //     gameBtns.forEach(el=> el.classList.add('disabled'));
            // } else {
            //     turnInstruction.forEach(el=> el.style.display = 'none');
            // }
        
        
        console.log(window.orientation)


        /**
         * Меняем отображение игры в зависимости от устройства
        */
        const gameBtns = document.querySelectorAll('.game__notice-btn');
        const turnInstruction = document.querySelectorAll('.game__turn');

        //Переменная для обнаружения мобильных устройств
        const mobs = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
        // Переменная для обнаружения сенсорного устройства
        const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

        // Если пользователь использует мобильное сенсорное устройство или планшет
        if (mobs || isTouchDevice) {
            //Скрываем Изображение, которое использовалось для ховер-эффекта
            hvrImage.style.display = 'none';
            //Скрываем оповещение "Враг найден"
            detectedNotice.style.display = 'none';
            
            //Если ширина экрана меньше 767 пикс
            if(window.innerWidth <= 767) {
                game.classList.add('tablets');
                game.classList.add('mobile');
            } else {
                game.classList.add('tablets');
            }

            //При загрузке страницы проверяем ориентацию устройства
            if (window.innerHeight > window.innerWidth) {//Если вертикальная
                //Отключаем кнопку старт и включаем инструкцию переверни экран
                gameBtns.forEach(el=> el.classList.add('disabled'));
                turnInstruction.forEach(el=> el.style.display = 'block');
            }
            if (window.innerHeight < window.innerWidth) {//Если горизонтальная
                //Включаем кнопку старт и убираем инструкцию переверни экран
                gameBtns.forEach(el=> el.classList.remove('disabled'));
                turnInstruction.forEach(el=> el.style.display = 'none');
            }

            //Отслеживаем смену ориентации устройства
            window
            .matchMedia('(orientation: portrait)')
            .addListener(function (m) {
                if (m.matches) {//Если экран не поворачивали
                    //Выключаем кнопки в окне оповещений
                    gameBtns.forEach(el=> el.classList.add('disabled'));
                    turnInstruction.forEach(el=> el.style.display = 'block');

                    //Добавляем класс портрет
                    game.classList.add('portrait');
                } else {//Если экран повернули
                    //Включаем кнопки в окне оповещений
                    gameBtns.forEach(el=> el.classList.remove('disabled'));
                    turnInstruction.forEach(el=> el.style.display = 'none');

                    //Удаляем класс портрет
                    game.classList.remove('portrait');
                }
            });
        } else {//Если пользователь зашел через десктоп
            //Находим оповещение о том, что надо перейти на просмотр страницы в полном экране
            const fullscreenNotice = document.querySelector('.game__fullscreen');

            //Как только пользователь начинает игру
            gameStartBtn.addEventListener('click', ()=> {
                //Получаем ширину и высоту вьюпорта
                const windowW = window.innerWidth;
                const windowH = window.innerHeight;
                
                //Если ширина экрана недостаточная для нормальной игры
                if(windowW / windowH <= 1.76) {
                    //Показываем ему оповещение
                    fullscreenNotice.classList.add('show');
                }
            });

            //При изменении размера вьюпорта
            window.addEventListener('resize', ()=> {
                //Если пользователь уже начал игру
                if(!game.classList.contains('game-beginning')) {
                    //Получаем ширину и высоту вьюпорта
                    const windowW = window.innerWidth;
                    const windowH = window.innerHeight;

                    if(windowW / windowH > 1.76) {//Если ширина достаточная для нормальной игры
                        //Убираем оповещение
                        fullscreenNotice.classList.remove('show');
                    } else {//В противном случае
                        //Показываем оповещение снова
                        fullscreenNotice.classList.add('show');
                    }
                }
            });
        }
    }
}

export default game;