function tabs () {
    /*
        Standard tabs options
    */
    const tabBtns = document.querySelectorAll('.tab-btn');

    if(tabBtns.length > 0) {
        tabBtns.forEach(el=> {
            el.addEventListener('click', function() {
                const parent = this.closest('.tab-parent');
                const tabContent = parent.querySelector('.tab-content');
                const tabWrapper = parent.querySelector('.tab-wrapper');

                let tabContentH = tabContent.offsetHeight;

                this.classList.toggle('active');

                if(this.classList.contains('active')) {
                    tabWrapper.style.height = 0 + 'px';
                    setTimeout(()=> {this.style.marginBottom = 0 + 'px'}, 300)
                } else {
                    this.style.marginBottom = 30 + 'px';
                    tabWrapper.style.height = tabContentH + 'px';
                }
            });
        });
    }


    /*
    Change set image on product page
    */
    const productSet = document.querySelectorAll('.product__set');

    if(productSet.length > 0) {
        let setDesktop = document.querySelector('.product__set--desktop');
        let setTablets = document.querySelector('.product__set--tablets');
        let setMobs = document.querySelector('.product__set--mob');

        if(window.innerWidth >= 1261) {
            setTablets.remove();
            setMobs.remove();
        } else if(window.innerWidth <= 1260 && window.innerWidth >= 768) {
            setDesktop.remove();
            setMobs.remove();
        } else if(window.innerWidth <= 767) {
            setDesktop.remove();
            setTablets.remove();
        }

        const setImg = document.querySelector('.product__set-img');
        const setImgSrc = setImg.src;
        const trigger = document.querySelectorAll('.product__trigger');

        trigger.forEach((el, i)=> {
            el.addEventListener('mouseenter', function() {
                const targetSet = this.dataset.settrigger;
                const targetTxt = document.querySelector(`[data-setTxt="${targetSet}"]`);

                setImg.src = `../../assets/images/product-details/sets/${targetSet}.jpg`;
                targetTxt.classList.add('show');
            });

            el.addEventListener('mouseleave', function() {
                const targetSet = this.dataset.settrigger;
                const targetTxt = document.querySelector(`[data-setTxt="${targetSet}"]`);
                
                setImg.src = setImgSrc;
                targetTxt.classList.remove('show');
            });
        });
    }
}

export default tabs;