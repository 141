function lottie () {
    /*
    Autoplay animation for all page
    */
    const lottieAutoplay = document.querySelectorAll('.lottie-autoplay');

    if(lottieAutoplay.length > 0) {
        lottieAutoplay.forEach(el=> {
            let anim = bodymovin.loadAnimation({
                container: el,
                path: '/assets/js/lottie-files/vizam-background-logo.json',
                renderer: 'svg',
                loop: true,
                autoplay: true,
            });
        });
    }


    /*
    About page (Why Vizam)
    */
    const lottieAbout = document.querySelector('.about__lottie');

    if(lottieAbout && window.innerWidth >= 1261) {
        // const extra = document.querySelectorAll('.lottie-extra');
        // let extraH = 0;

        // extra.forEach(el => {
        //     const rect = el.offsetHeight; 
        //     extraH += rect; 
        // });

        let durationAnim = document.body.offsetHeight - window.innerHeight;

        // if(window.innerWidth >= 1261) 
        //     durationAnim = document.body.offsetHeight - window.innerHeight;
        // if(window.innerWidth <= 1260) 
        //     durationAnim = document.body.offsetHeight - window.innerHeight - extraH;


        //Initializing lottie animations
        let lottie = {
            container: lottieAbout,
            path: '/assets/js/lottie-files/vizam-why-vizam.json',
            renderer: 'svg',
            autoplay: false,
        };

        
        //Increasing the animation size by the entire width of the screen
        let intervalId;

        function checkElement() {
            if (lottie.container) { 
                if (lottie.container.firstElementChild) {
                    lottie.container.firstElementChild.setAttribute("preserveAspectRatio", "xMidYMid slice");
                    clearInterval(intervalId);
                }
            }
        }
        checkElement();
        intervalId = setInterval(checkElement, 300);

        const anim = bodymovin.loadAnimation(lottie);
        
        //Animate to scroll
        window.addEventListener('scroll', ()=> {
            if(window.scrollY >= window.innerHeight / 2) {
                animatebodymovin(durationAnim, anim);
            }
        });
        
        //Creating an animation function
        function animatebodymovin(duration, animObject) {
            let scrollPosition =  window.scrollY;
            let maxFrames = animObject.totalFrames;

            let frame = scrollPosition / (duration / maxFrames);
            animObject.goToAndStop((frame - 1), true);

            // if(anim.currentFrame >= anim.totalFrames) {
            //     animObject.goToAndStop(anim.totalFrames, true);
            // } else {
            //     animObject.goToAndStop((frame - 1), true);
            // }

            // if(window.innerWidth <= 1260) {
            //     const stopLottie = document.querySelector('.about__lottie-stop');
            //     const stopLottieH = stopLottie.offsetHeight;
            //     const stopLottieOffset = stopLottie.offsetTop;
            //     const animWrapper = document.querySelector('.about__lottie-wrapper');
    
            //     document.addEventListener('scroll', ()=> {
            //         if(window.scrollY >= stopLottieOffset + (stopLottieH / 2) - (window.innerHeight / 2)) {
            //             animWrapper.style.position = 'relative';
            //         } else {
            //             animWrapper.style.position = 'fixed';
            //             animObject.goToAndStop((frame - 1), true);
            //         }
            //     });
            // }
        }
    }


    /*
    Animation lenses on Tech page
    */
    const lottieLenses = document.querySelector('.tech__lottie--lenses');

    if(lottieLenses) {
        //Initializing lottie animations
        const durationAnim = window.innerHeight * 2,

        lottie = {
            container: lottieLenses,
            path: '/assets/js/lottie-files/vizam-tech-first.json',
            renderer: 'svg',
            autoplay: false,
        };

        const anim = bodymovin.loadAnimation(lottie);
        
        //Animate to scroll
        window.addEventListener('scroll', ()=> {
            animatebodymovin(durationAnim, anim);
        });

        //Creating an animation function
        function animatebodymovin(duration, animObject) {
            let scrollPosition =  window.scrollY + 50;
            let maxFrames = animObject.totalFrames;

            let frame = scrollPosition / (duration / maxFrames);
            animObject.goToAndStop((frame - 1), true);
        }
    }


    /*
    Animation frame on Tech page
    */
    const lottieFrameOrion = document.querySelector('.tech__lottie--frame');

    if(lottieFrameOrion) {
        //Initializing lottie animations
        const durationAnim = window.innerHeight * 2;

        const lottieOrion = {
            container: lottieFrameOrion,
            path: '/assets/js/lottie-files/vizam-tech-orion.json',
            renderer: 'svg',
            autoplay: false,
        };

        const lottieFrameVega = document.querySelector('.tech__lottie--vega');
        
        const lottieVega = {
            container: lottieFrameVega,
            path: '/assets/js/lottie-files/vizam-tech-vega.json',
            renderer: 'svg',
            autoplay: false,
        };

        const animOrion = bodymovin.loadAnimation(lottieOrion);
        const animVega = bodymovin.loadAnimation(lottieVega);
        
        //Animate to scroll
        window.addEventListener('scroll', ()=> {
            animatebodymovin(durationAnim, animOrion);
            animatebodymovin(durationAnim, animVega);
        });

        let frame;
        
        //Creating an animation function
        function animatebodymovin(duration, animObject) {
            let scrollPosition =  window.scrollY - (document.querySelector('.tech__lottie-wrapper--frame').offsetTop - window.innerHeight);
            let maxFrames = animObject.totalFrames;

            frame = scrollPosition / (duration / maxFrames);
            animObject.goToAndStop((frame - 1), true);
        }


        //Changing the animation when clicking on the buttons
        const changeBtns = document.querySelectorAll('.tech__content-btns-btn');

        lottieFrameOrion.classList.add('show');
        changeBtns[0].classList.add('active');

        function changeAnim(e) {
            e.preventDefault();

            changeBtns.forEach(el=> {
                el.classList.remove('active');
            });
            this.classList.add('active');

            const targetCategory = this.dataset.changelottie;

            if(targetCategory === 'orion') {
                lottieFrameOrion.classList.add('show');
                lottieFrameVega.classList.remove('show');
            }

            if(targetCategory === 'vega') {
                lottieFrameOrion.classList.remove('show');
                lottieFrameVega.classList.add('show');
            }
        }


        changeBtns.forEach(el=> {
            el.addEventListener('mouseenter', changeAnim);
            el.addEventListener('click', changeAnim);
        });
    }


    /*
    Animation head on Tech page
    */
    const lottieHead = document.querySelector('.tech__lottie--head');

    if(lottieHead) {
        //Initializing lottie animations
        const durationAnim = window.innerHeight * 2,

        lottie = {
            container: lottieHead,
            path: '/assets/js/lottie-files/vizam-tech-head.json',
            renderer: 'svg',
            autoplay: false,
        };

        
        const anim = bodymovin.loadAnimation(lottie);
        
        //Animate to scroll
        window.addEventListener('scroll', ()=> {
            animatebodymovin(durationAnim, anim);
        });
        
        //Creating an animation function
        function animatebodymovin(duration, animObject) {
            let scrollPosition =  window.scrollY - (document.querySelector('.tech__lottie-wrapper--head').offsetTop - (window.innerHeight + 200));
            let maxFrames = animObject.totalFrames;

            let frame = scrollPosition / (duration / maxFrames);
            animObject.goToAndStop((frame - 1), true);
        }
    }

}

export default lottie;